import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import Header from '../components/header';
import Footer from '../components/footer';
import Button from '../components/button';

const IndexPage = ( props ) => {

    return (

      <>

        <Header img={props.data.logo.childImageSharp.fluid} />

        <div className="mt-40 bg-beige-100 sm:mt-24 sm:pt-2 sm:flex flex-wrap item-center">

            <div className="w-full sm:w-1/2">
              <BackgroundImage
                className="w-full h-full min-h-128"
                fluid={props.data.hiltonNotebooks.childImageSharp.fluid} />
            </div>

            <div className="w-full p-4 sm:w-1/2 sm:p-8 sm:flex lg:p-16 xl:p-32 flex-col justify-center">
                <h1>We are branded notebooks</h1>
                <p>At Curious Book Co. our designers create bespoke notebooks with one mission in mind: yours. Whether it's a book for your clients or something to enhance your business profile, we work closely with your vision to create a product that achieves your brand's intentions and leaves a lasting impression.</p>
                <Button url="#form" text="Submit Enquiry" classes="mt-4 w-auto mr-auto"/>
            </div>

        </div>


        <div className="teal-section bg-teal-400 sm:flex flex-wrap item-center text-white">

            <div className="relative z-10 w-full sm:w-1/2 sm:flex items-center justify-end p-8">
              <Img className="w-full mx-auto sm:w-2/3" fluid={props.data.tailoredBrand.childImageSharp.fluid} />
            </div>

            <div className="relative z-10 w-full p-8 sm:w-1/2 sm:flex sm:p-16 xl:p-32 flex-col justify-center items-start text-right">
              <h2 className="w-full text-white text-right lg:w-2/3">Tailored to your brand</h2>
              <p className="lg:w-2/3">Creativity flows from start to finish with the team at Curious Book Co. We measure up our clients through personal communication, attention to branding and ensuring every detail is noted before creating the perfect notebook.</p>
            </div>

        </div>


        <div className="yellow-section bg-yellow-500 sm:flex flex-wrap items-center">

            <div className="relative z-10 w-full p-8 sm:w-1/2 sm:flex sm:p-16 xl:p-32 flex-col justify-center items-end">
              <h2 className="lg:w-2/3">Cover to cover communication</h2>
              <p className="lg:w-2/3">Are you launching a product, hosting a client event or simply looking for an idea for a gift? Our notebooks are perfect mementos, making sure your message is not only noted but refreshed with every use.</p>
            </div>

            <div className="relative z-10 w-full sm:w-1/2 sm:flex items-center justify-start p-8">
              <Img className="w-full mx-auto sm:w-2/3" fluid={props.data.coverToCover.childImageSharp.fluid} />
            </div>

        </div>


        <div className="teal-section bg-teal-400 sm:flex flex-wrap item-center text-white">

            <div className="relative z-10 w-full sm:w-1/2 sm:flex items-center justify-end p-8">
              <Img className="w-full mx-auto sm:w-2/3" fluid={props.data.masterpieces.childImageSharp.fluid} />
            </div>

            <div className="relative z-10 w-full p-8 sm:w-1/2 sm:flex sm:p-16 xl:p-32 flex-col justify-center items-start text-right">
              <h2 className="w-full text-white text-right lg:w-2/3">Creating masterpieces</h2>
              <p className="lg:w-2/3">Whether you’re an established law firm or a new creative agency – the little details of your brand’s identity matter to us. We’ll work to do more than make a simple branded product; we’ll create something that truly makes you leap off the page – why settle for anything less?</p>
            </div>

        </div>

        <div className="text-center">

            <div className="p-8 sm:p-16">
              <h2 className="text-center">Our Clients</h2>
              <p className="md:w-1/2 mx-auto">We work with companies of all different types, shapes and sizes. There is always a reason to have a branded notebook, the clients we work with reflect our mission to deliver quality in every way from product, service and delivery.</p>
            </div>

            <div className="bg-white p-4 flex flex-wrap ">
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.powerday.childImageSharp.fluid} alt="Powerday" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.clearChannel.childImageSharp.fluid} alt="Clear Channel" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.hilton.childImageSharp.fluid} alt="Hilton" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.fetch.childImageSharp.fluid} alt="Fetch" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.charlesRussell.childImageSharp.fluid} alt="Charles Russell" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.h2YachtDesign.childImageSharp.fluid} alt="H2 Yacht Design" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.stimulatingMinds.childImageSharp.fluid} alt="Stimulating Minds" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.onehq.childImageSharp.fluid} alt="1HQ" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.wePlay.childImageSharp.fluid} alt="WePlay" />
              <Img className="w-full sm:w-1/2 lg:w-1/5 border-white border-8" fluid={props.data.perowne.childImageSharp.fluid} alt="Perowne" />
            </div>


        </div>

        <div className="text-center">
          <h2 className="text-center my-8 sm:my-16">Customisation</h2>
          <BackgroundImage
              className="w-full h-128 bg-cover bg-center bg-fixed"
              fluid={props.data.notebookBackground.childImageSharp.fluid} />
        </div>

        <div className="bg-beige-100 flex flex-wrap items-center justify-around p-8">

          <div className="px-8 w-full sm:w-1/2 lg:w-1/4">
            <Img className="w-full" fluid={props.data.options1.childImageSharp.fluid} alt="Customisable Options" />
          </div>

          <div className="px-8 w-full sm:w-1/2 lg:w-1/4">
            <Img className="w-full" fluid={props.data.options2.childImageSharp.fluid} alt="Customisable Options" />
          </div>

          <div className="px-8 w-full sm:w-1/2 lg:w-1/4">
            <Img className="w-full" fluid={props.data.options3.childImageSharp.fluid} alt="Customisable Options" />
          </div>

          <div className="px-8 w-full sm:w-1/2 lg:w-1/4">
            <Img className="w-full" fluid={props.data.options4.childImageSharp.fluid} alt="Customisable Options" />
          </div>

        </div>

        <div className="yellow-section bg-yellow-500 text-center p-8">

          <div className="relative z-10">
            <h2 className="text-center my-8 sm:my-16">Testimonials</h2>

            <div className="flex flex-wrap justify-center">

              <div className="w-full p-8 flex flex-wrap flex-col justify-between lg:w-1/3 lg:px-16 xl:w-1/4">
                <p className="testimonial-content">The McLaren orange notebooks from Curious Book Co. add a bespoke touch to our service. They are the perfect hand over gift for our customers. High quality and beautiful finish.</p>
                <Img className="mt-8 w-48 mx-auto" fluid={props.data.mclarenLogo.childImageSharp.fluid} alt="Mclaren Logo" />
              </div>

              <div className="w-full p-8 flex flex-wrap flex-col justify-between lg:w-1/3 lg:px-16 xl:w-1/4">
                <p className="testimonial-content">Having previously sent out the books as a promotional tool we have had clients calling up asking for replacements as soon as the books had been filled. The quality of the books is second to none.</p>
                <Img className="mt-8 w-48 mx-auto" fluid={props.data.stimulatingMindsLogo.childImageSharp.fluid} alt="Stimulating Minds Logo" />
              </div>

              <div className="w-full p-8 flex flex-wrap flex-col justify-between lg:w-1/3 lg:px-16 xl:w-1/4">
                <p className="testimonial-content">I have found your product to be of the best quality - very well made with high quality soft texture, well cut, numbered lined pages and a surprise smaller notebook stored in a pocket on the inside back cover.</p>
                <Img className="mt-8 w-32 mx-auto" fluid={props.data.lamborghiniLogo.childImageSharp.fluid} alt="Lamborghini Logo" />
              </div>

            </div>

          </div>

        </div>

        <div id="form" className="bg-teal-400 text-center text-white p-8">

          <div className="p-8 sm:p-16 sm:pb-8">
            <h2 className="text-center text-white mb-16">See for yourself</h2>
            <p className="md:w-1/2 mx-auto">Wondering how you’ll look on our notebooks? Find out now! Fill out the details below and send us your chosen branding. We’ll get to work on a digital presentation to show you exactly what a Curious Book will look like in your image.</p>
          </div>

          <form className="bg-white p-8 w-full mx-auto mb-8 sm:w-1/2 sm:mb-16 lg:w-1/4" method="POST" data-netlify="true" name="Contact Form" action="/thankyou">
            <input type="hidden" name="form-name" value="Contact Form" />
            <label className="block w-full mb-4">
              <input name="Name" className="w-full bg-gray-100 text-teal-500 border border-teal-500 rounded p-2" type="text" placeholder="Name *" required />
            </label>
            <label className="block w-full mb-4">
              <input name="Email address" className="w-full bg-gray-100 text-teal-500 border border-teal-500 rounded p-2" type="email" placeholder="Email address *" required />
            </label>
            <label className="block w-full mb-4">
              <input name="Phone Number" className="w-full bg-gray-100 text-teal-500 border border-teal-500 rounded p-2" type="tel" placeholder="Telephone Number *" required />
            </label>
            <label className="block w-full mb-4">
              <input name="Company Name" className="w-full bg-gray-100 text-teal-500 border border-teal-500 rounded p-2" type="text" placeholder="Company" />
            </label>
            <label className="block">
              <button className="btn mt-2 cursor-pointer" type="submit">Submit Enquiry</button>
            </label>
          </form>

        </div>

        <Footer img={props.data.logo.childImageSharp.fluid} />
    </>

  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hiltonNotebooks: file(relativePath: { eq: "hilton-notebooks.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    tailoredBrand: file(relativePath: { eq: "tailored-brand.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    coverToCover: file(relativePath: { eq: "cover-to-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    masterpieces: file(relativePath: { eq: "masterpieces.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    notebookBackground: file(relativePath: { eq: "notebook-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    onehq: file(relativePath: { eq: "1hq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    charlesRussell: file(relativePath: { eq: "charles-russell.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    clearChannel: file(relativePath: { eq: "clear-channel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    fetch: file(relativePath: { eq: "fetch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    h2YachtDesign: file(relativePath: { eq: "h2-yacht-design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hilton: file(relativePath: { eq: "hilton.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    perowne: file(relativePath: { eq: "perowne.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    powerday: file(relativePath: { eq: "powerday.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    stimulatingMinds: file(relativePath: { eq: "stimulating-minds.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    wePlay: file(relativePath: { eq: "we-play.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    options1: file(relativePath: { eq: "options-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    options2: file(relativePath: { eq: "options-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    options3: file(relativePath: { eq: "options-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    options4: file(relativePath: { eq: "options-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mclarenLogo: file(relativePath: { eq: "mclaren-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    stimulatingMindsLogo: file(relativePath: { eq: "stimulating-minds-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    lamborghiniLogo: file(relativePath: { eq: "lamborghini-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },

  }
`;
